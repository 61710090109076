.video-controls-left-alignment {
    text-align: center;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
}

.video-controls-right-alignment {
    text-align: center;
    position: absolute;
    top: 15px;
    right: 2%;
    z-index: 2;
}

.video-controls-right-alignment-chat-active {
    text-align: center;
    position: absolute;
    top: 15px;
    right: 29%;
    z-index: 2;
}

.video-controls-center-alignment {
    text-align: center;
    position: absolute;
    bottom: 15px;
    right: calc(50vw - 5.5vmin);
    z-index: 2;
}

.video-controls-mic {
    height: 6vmin;
    cursor: pointer;
    padding-right: 2vmin;
    padding-left: 0.5vmin;
}

.video-controls-video {
    height: 8vmin;
    cursor: pointer;
    padding-right: 2vmin;
}

.video-controls-fullscreen {
    height: 5vmin;
    cursor: pointer;
    padding-right: 0.5vmin;
}

.video-controls-chat {
    height: 5.5vmin;
    cursor: pointer;
    padding-left: 2vmin;
    padding-top: 0.5vmin ;
}

.video-controls-record {
    height: 5vmin;
    cursor: pointer;
    padding-right: 3vmin;
}

.video-controls-screenshot {
    height: 5vmin;
    cursor: pointer;
    padding-right: 3vmin;
}

.video-controls-stop {
    height: 6vmin;
    cursor: pointer;
    padding-left: 2vmin;
}

.video-controls-whiteboard-fullscreen {
    height: 6vmin;
    cursor: pointer;
    padding-right: 3vmin;
    padding-top: 1vmin;
}

.video-controls-whiteboard {
    height: 6vmin;
    cursor: pointer;
    padding-top: 1vmin;
}

.video-controls-grid {
    height: 5vmin;
    cursor: pointer;
    padding-top: 1vmin;
    padding-left: 3vmin;
    padding-right: 3vmin;
}

.video-controls-container-left {
    background-color: #50505052;
    min-height: 10vmin;
    min-width: 20vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    border-radius: 10px;
    color: white;
}

.video-controls-container-right {
    background-color: #50505052;
    min-height: 10vmin;
    min-width: 44vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    border-radius: 10px;
    color: white;
}

.video-controls-container-right-whiteboard-inactive {
    background-color: #50505052;
    min-height: 10vmin;
    min-width: 44vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    border-radius: 10px;
    color: white;
}

.video-controls-container-right-whiteboard-inactive-one {
    background-color: #50505052;
    min-height: 10vmin;
    min-width: 34vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    border-radius: 10px;
    color: white;
}

.video-controls-container-center {
    background-color: #50505052;
    min-height: 10vmin;
    min-width: 11vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    border-radius: 10px;
    color: white;
}
