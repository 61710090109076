.room-preview {
    padding: 10px 16px;
    margin: 20px 0;
    width: 200px;
    background-color: #D3D3D3;
    border-bottom: 1px solid #fafafa;
}

.room-preview:hover {
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
}

.room-preview button {
    background: #f1356d;
    color: #fff;
    border: 0;
    height: 40px;
    padding: 10px;
    border-radius: 8px;
    margin-left: 50px ;
    cursor: pointer;
}

.room-preview h2 {
    font-size: 20px;
    color: #f1356d;
    text-align: center;
    margin-bottom: 8px;
}

.room-preview p {
    font-size: 20px;
    text-align: center;
    color: black;
}

.join-room-help-text {
    padding: 10px 16px;
    margin: 20px 0;
    color: #f1356d;
    text-align: center;
    border-bottom: 1px solid #fafafa;
}