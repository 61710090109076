.remote-video-alignment {
  z-index: 2;
}

.remote-video-alignment_self {
  text-align: center;
  position: absolute;
  right: 0px;
  z-index: 2;
}

.remote-video-whiteboard {
  text-align: center;
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 2;
}

.remote-video-unavailable {
  pointer-events: none;
}

.remote-video-unavailable-whiteboard-active {
  max-height: 8.4vmin;
  max-width: 15vmin;
  border-radius: 10px;
  pointer-events: none;
}

.remote-video-container {
  background-color: #3F3F3F;
  height: 50vmax;
  width: 100vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.remote-video-container_self {
  background-color: #3F3F3F;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  z-index: 2;
}

.remote-video-container-whiteboard-active {
  background-color: #696969;
    max-height: 10vmin;
    max-width: 15vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
  color: #61dafb;
}

.self-video-alignment {
  text-align: center;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 2;
}

.self-video-alignment-grid {
  text-align: center;
  position: absolute;
  right: 0px;
  z-index: 2;
}

.self-video-unavailable {
  height: 10vmin;
  pointer-events: none;
  min-height: 20vmin;
  min-width: 30vmin;
  border-radius: 10px;
}

.self-video-container {
  background-color: #696969;
  min-height: 20vmin;
  min-width: 30vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.self-video-unavailable-whiteboard-active {
  max-height: 8.4vmin;
  max-width: 15vmin;
  border-radius: 10px;
  color: black;
  pointer-events: none;
}

.self-video-container-whiteboard-active {
  background-color: #696969;
  max-height: 8.4vmin;
  max-width: 15vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: calc(10px + 2vmin);
  color: black;
}

.self-video-mirror {
transform: rotateY(180deg);
-webkit-transform:rotateY(180deg); /* Safari and Chrome */
-moz-transform:rotateY(180deg); /* Firefox */
}

.video-name-tag {
display: inline-block;
position: absolute;
color: white;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
z-index: 1;
background-color: rgba(128, 128, 128, 0.5);
border-radius: 5px;
bottom: 3px;
left: 3px;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

@media screen and (min-width: 200px) {
  .video-name-tag {
    width: auto;
    padding-right: 4px;
    padding-left: 4px;
    font-size: smaller;
  }
}

@media screen and (min-width: 480px) {
  .video-name-tag {
    width: auto;
    padding-right: 4.2px;
    padding-left: 4.2px;
    font-size: medium;
  }
}

@media screen and (min-width: 769px) {
  .video-name-tag {
    padding-right: 5px;
    padding-left: 5px;
    font-size: large;
  }
}

/*
@media screen and (max-width: 480px) {

  .self-video{
      bottom: 1%;
  }
  
}
*/
