.whiteboard-alignment {
  text-align: center;
  z-index: 0;
}

.whiteboard-container {
  background-color: #ffffff;
  min-height: 99vh;
  min-width: 99vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.whiteboard-background {
    background-color: #3F3F3F;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

/*
  @media screen and (orientation:portrait) {

    .whiteboard-container{
        min-height: 80vh;
    }
    .whiteboard-alignment{
      bottom: 10vmin;
    }
    
}
*/
